import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import ReactPlayer from "react-player";
import Layout from "./../components/Layout";
import ArticleBody from "./../components/ArticleBody";
import MiscSocialSmall from "./../components/icons/MiscSocialSmall";
import { H5SmallMedium, H2Italic } from "./../components/Typography";
import EventGrid from "./../components/EventGrid";
import PlayIcon from "./../components/VideoHero/images/button-video-play@3x.png";
import {
  OurStoryTop,
  OurStoryBottom,
} from "./../components/OurStoryStyles/elements";

const noRecommended = true;

const OurStoryPage = () => {
  const ourStoryQuery = useStaticQuery(graphql`
    query OurStoryQuery {
      craftOurStoryOurStoryEntry {
        subtitle
        title
        seoTitle
        seoDescription
        editorialVideoRemoteVideoUrl
        quote
        quoteAttribution

        recommendations {
          ... on Craft_articles_sounds_Entry {
            id
            title
            slug
            typeHandle
            editorialType
            editorialShortDescription
            headerImage {
              ... on Craft_editorialAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on Craft_articles_words_Entry {
            id
            title
            slug
            typeHandle
            editorialType
            editorialShortDescription
            headerImage {
              ... on Craft_editorialAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on Craft_articles_videos_Entry {
            id
            title
            slug
            typeHandle
            editorialType
            editorialShortDescription
            headerImage {
              ... on Craft_editorialAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on Craft_articles_images_Entry {
            id
            title
            slug
            typeHandle
            editorialType
            editorialShortDescription
            headerImage {
              ... on Craft_editorialAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }

        richContent {
          ... on Craft_richContent_matterportEmbed_BlockType {
            typeHandle
            matterportUrl
          }
          ... on Craft_richContent_podiantPlayer_BlockType {
            podiantUrl
            typeHandle
          }
          ... on Craft_richContent_anghamiPlayer_BlockType {
            anghamiUrl
            typeHandle
          }
          ... on Craft_richContent_soundcloudPlayer_BlockType {
            soundcloudUrl
            typeHandle
          }
          ... on Craft_richContent_spotifyPlayer_BlockType {
            spotifyUrl
            typeHandle
          }
          ... on Craft_richContent_image_BlockType {
            id
            typeHandle
            imageWithCaptionImage {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            imageWithCaptionCaption
          }
          ... on Craft_richContent_slideShow_BlockType {
            id
            typeHandle
            images {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            carouselCaption
          }
          ... on Craft_richContent_richText_BlockType {
            id
            typeHandle
            richText
          }
          ... on Craft_richContent_imageSlider_BlockType {
            typeHandle
            imageSliderImages {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            imageSliderCaptions {
              captionLine4
              captionLine3
              captionLine2
              captionLine1
            }
          }
          ... on Craft_richContent_videoWithCaption_BlockType {
            typeHandle
            videoWithCaptionCaption
            videoWithCaptionVideoOnTheLeft
            videoWithCaptionUseCredit
            videoWithCaptionUrl
            videoWithCaptionVideo {
              url
            }
          }
          ... on Craft_richContent_photoWithQuote_BlockType {
            typeHandle
            photoWithQuoteImage {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            photoWithQuoteImageOnTheLeft
            photoWithQuoteImageQuote
            photoWithQuoteImageQuoteAttribution
          }
        }
      }
    }
  `).craftOurStoryOurStoryEntry;
  const {
    title,
    richContent,
    subtitle,
    seoTitle,
    seoDescription,
    quote,
    quoteAttribution,
    editorialVideoRemoteVideoUrl,
    recommendations,
  } = ourStoryQuery;
  // console.log(recommendations);
  var SeoStrory = ourStoryQuery.seoTitle?ourStoryQuery.seoTitle:null
  var SeoDesc = ourStoryQuery.seoDescription?ourStoryQuery.seoDescription:null;
  
  
  return (
    <Layout
      backgroundColor={"var(--black)"}
      footerColor={"var(--red)"}
      title={SeoStrory}
      SEODescription={SeoDesc}     
    >
      <OurStoryTop accentColor={"var(--green)"} style={{marginTop:'50px'}}>
        <MiscSocialSmall color={"var(--green)"} />
        <H5SmallMedium dangerouslySetInnerHTML={{ __html: quoteAttribution }} />
        <H2Italic>{quote}</H2Italic>
        {editorialVideoRemoteVideoUrl ? (
          <div className="player-wrapper">
            <ReactPlayer
              url={editorialVideoRemoteVideoUrl}
              controls={true}
              className="react-player"
              width={"100%"}
              height={"100%"}
              light
              playing
              playIcon={
                <img src={PlayIcon} alt="play" width={200} height={200} />
              }
            />
          </div>
        ) : null}
      </OurStoryTop>
      <OurStoryBottom>
        <ArticleBody
          topline={title}
          subTitle={subtitle}
          subTitleColor={"var(--purple)"}
          mainColumn={richContent}
          color={"var(--purple)"}
          textColor={"var(--black)"}
        />
        {noRecommended ? null : (
          <EventGrid
            events={recommendations}
            noDates
            textColor={"var(--black)"}
            accentColor={"var(--purple)"}
            introCard={"Related writings"}
            useBottomRule
          />
        )}
      </OurStoryBottom>
    </Layout>
  );
};

export default OurStoryPage;
